<template>
  <b-modal id="modal-catalog" v-model="showModal" hide-header hide-footer centered :no-close-on-backdrop="disableClose" :no-close-on-esc="disableClose" body-class="position-relative" dialog-class="m-auto">
    <img alt="logo" src="~/mlearn/icons/modal/close-modal.svg" class="close pointer position-absolute" @click="closeModal()">
    <template v-if="step === 1">
      Quý khách chưa đăng ký DV mobiLearn. Để đăng ký DV, soạn <b>DK2</b> gửi <b>9979</b> (30.000đ/tháng, gia hạn theo tháng) hoặc click <span class="text-uppercase text-danger font-weight-bold pointer" @click="registerSms">ĐĂNG KÝ</span>.
    </template>
    <!-- <template v-else-if="step === 2">
      <div>Để xác nhận ĐĂNG KÝ DV, Quý khách vui lòng soạn tin nhắn xác nhận:<br><b>Y2</b> gửi <b>9979</b>.</div>
      <div class="action text-center">
        <button class="bg-primary text-white accept" @click="confirmSms">{{isMobile ? 'Gửi tin nhắn' : 'Đóng'}}</button>
      </div>
    </template> -->
    <template v-else-if="step === 3">
      <div>Xin chào thuê bao {{ user.mobile }},<br>Quý khách đang sử dụng DV mobiLearn</div>
    </template>
    <template v-else-if="step === 4">
      <div>Quý khách không xem được nội dung này do chưa gia hạn dịch vụ thành công. Vui lòng nạp thêm tiền để tiếp tục sử dụng</div>
    </template>
    <template v-else-if="step === 5">
      <div>Siêu tiết kiệm 30.000 đồng/tháng (gia hạn theo tháng) được sử dụng trọn bộ thư viện và hỏi bài cùng gia sư trên mobiLearn</div>
      <div class="action text-center">
        <button class="bg-primary text-white accept" @click="registerLink">ĐĂNG KÝ VÀ NHẬN ƯU ĐÃI</button>
      </div>
    </template>
    <template v-else-if="step === 6">
      <div>Chào mừng Quý khách đến với DV mobiLearn (30.000đ/tháng, gia hạn theo tháng).</div>
    </template>
    <template v-else>
      <div v-if="viettel">
        Chào mừng TB {{ viettel }} đến với DV mobiLearn – Cùng học hỏi và khám phá kho tri thức khổng lồ nào! Để đăng ký DV, soạn DK2 gửi 9979 (30.000đ/tháng, gia hạn theo tháng) hoặc click <span class="text-uppercase text-danger font-weight-bold pointer" @click="registerWap">ĐĂNG KÝ</span>.
      </div>
      <div v-else>Chào mừng Quý khách đến với DV mobiLearn (30.000đ/tháng, gia hạn theo tháng).</div>
    </template>
  </b-modal>
</template>

<script>
import Resource from '~/common/api/resource'

import { getLinkRegister } from '~/common/api/viettel'
const checkSubResource = new Resource('price/sub/user')
export default {
  data () {
    return {
      showModal: false,
      subInfo: null,
      step: 0
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    isMobile () {
      return this.$store.state.isMobile
    },
    disableClose () {
      return true
    },
    viettel () {
      return this.$store.state.viettel
    }
  },
  async mounted () {
    const catelog = false
    if (!catelog) { // Not remember close
      if (this.user) {
        const { data: sub } = await checkSubResource.list()
        this.subInfo = sub
        this.showModal = true
        if (!this.subInfo.expired_date && !this.viettel) { // Chưa đăng ký sub
          this.step = 1
        } else if (this.subInfo.is_expired) { // Đã đăng ký và hết hạn
          this.step = 4
        } else if (this.subInfo.first_time === 1) { // dang ky lan dau
          this.step = 6
        } else {
          this.step = 3
        }
      }
    }
  },
  methods: {
    registerSms () {
      this.closeModal()
      // this.step = 2
      if (this.isMobile) {
        window.open('sms:9979?&body=DK2', '_blank')
      }
    },
    registerWap () {
      this.step = 5
    },
    confirmSms () {
      this.closeModal()
      if (this.isMobile) {
        window.open('sms:9979?&body=Y2', '_blank')
      }
    },
    closeModal () {
      // Cookies.set('catalog', 1, { expires: 1 / 12 })
      this.showModal = false
    },
    registerLink () {
      const params = {
        mobile: this.$store.state.viettel
      }
      getLinkRegister(params)
        .then((response) => {
          const data = response.data
          if (data) {
            const link = data.link
            if (link) {
              window.location.href = link
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss">
  #modal-catalog{
    &___BV_modal_outer_{
      z-index: 9999 !important;
      .modal-dialog{
        max-width:350px;
        .modal-body{
          padding:32px 10px 20px;
          font-weight: 400;
          font-size: 1rem;
          color: #0A152A;
          .close{
            right: 10px;
            top: 10px;
            width:14px;
          }
          .action{
            margin-top: 1rem;
            button{
              border-radius: 22.5px;
              padding:10px 0;
              width: 200px;
              margin-right: 10px;
              &:last-child{
                margin-right: 0;
              }
              &.accept{
                background: #EE0032;
              }
              &.cancel{
                border: 1px solid #EE0032;
              }
            }
          }
        }
      }
    }
  }
</style>
